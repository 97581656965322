.navbar {
  background-color: #0049FF;
  color: white;
}


.navbar a {
  color: white;
}


.navbar a:hover {
  color: #F09606; 
}


#responsive-navbar-nav {
  margin-right: 20px;
}

.navbar-toggler{
  margin-right: 20px;
}


#txtblanco {
  font-family: 'nunito-ExtraBold', sans-serif; 
  font-size: 17px;
  margin-left:10px
}

.navbar-brand img {
  margin-left: 20px;
}

.logo-Menu {
  max-width: 200px;
  height: auto;
margin-left: 20px;
padding-bottom: 15px;
}



/* banner */


.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: auto;
}

.imgbanner {
  width: 100%; 
  height: auto;
  transition: transform 0.3s ease; 
}

/* @media only screen and (max-width: 450px) {
  .imgbanner {
    transform: scale(1); 
  }
} */



.banner img {
  max-width: 100%;
  height: auto;
}


@media (max-width: 768px) {
  .banner {
    flex-direction: column; 
  }
}


.Logo-top {
  width: 35%;
}

@media (max-width: 900px) {
  .Logo-top {
    width: 45%;
  }
}

@media (max-width: 900px) {
  .Logo-top {
    width: 55%;
  }
}


/* intro txt */
.intro {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 100px;
  margin-Bottom: 70px;
  font-size: 25px;
  font-family: 'nunito-Bold', sans-serif; 
  padding-left: 10px;
  padding-right: 10px;
}

/* .getAkrane {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #EFE747;
} */


/* fonts */

@font-face {
  font-family: 'beachday';
  src: url('../public/fonts/beachday.ttf') format('truetype'); /* Ajusta la ruta y el formato */
  font-weight: normal; /* Puedes ajustar la ponderación de la fuente */
  font-style: normal; /* Puedes ajustar el estilo de la fuente */
}


@font-face {
  font-family: 'nunito-black';
  src: url('../public/fonts/Nunito-Black.ttf') format('truetype'); /* Ajusta la ruta y el formato */
  font-weight: normal; /* Puedes ajustar la ponderación de la fuente */
  font-style: normal; /* Puedes ajustar el estilo de la fuente */
}

@font-face {
  font-family: 'nunito-bold';
  src: url('../public/fonts/Nunito-Bold.ttf') format('truetype'); /* Ajusta la ruta y el formato */
  font-weight: normal; /* Puedes ajustar la ponderación de la fuente */
  font-style: normal; /* Puedes ajustar el estilo de la fuente */
}

@font-face {
  font-family: 'nunito-ExtraBold';
  src: url('../public/fonts/Nunito-ExtraBold.ttf') format('truetype'); /* Ajusta la ruta y el formato */
  font-weight: normal; /* Puedes ajustar la ponderación de la fuente */
  font-style: normal; /* Puedes ajustar el estilo de la fuente */
}

@font-face {
  font-family: 'nunito-SemiBold';
  src: url('../public/fonts/Nunito-SemiBold.ttf') format('truetype'); /* Ajusta la ruta y el formato */
  font-weight: normal; /* Puedes ajustar la ponderación de la fuente */
  font-style: normal; /* Puedes ajustar el estilo de la fuente */
}


.a-link {
text-decoration: none;
color: #;
}



/* GET A KRANE */

.getAkrane {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #EFE747;
  margin-top: 60px;
  margin-bottom: 30px;
}

.GAK-subtitle {
  font-family: 'nunito-black', sans-serif; 
  font-size: 35px; 
  text-align: right;
  max-width: 1000px;
  margin-right: 15px;
  text-align: center;
height: auto;
}

@media only screen and (max-width: 709px) {
  .GAK-subtitle  {
    font-size: 25px;
  }
}

@media only screen and (max-width: 709px) {
  .getAkrane  {
    margin-top: 0px;
  }
}



#marginTop {
  margin-top: 60px;
}

.orange {
  color: #F09606;
}

.carouselHome {
margin-top: 60px;
}


/* GET A KRANE - CAROUSEL HOME */

.carousel-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px; /* Ajusta la altura según tus necesidades */

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain; /* Ajusta el tamaño de la imagen para que quepa en el contenedor */
  }

  &:not(:nth-child(2)) {
    width: 60%; /* Imágenes de los lados más pequeñas */
    #imgCarouse3 {
      filter: blur(2px); /* Aplica el efecto de desenfoque a las imágenes de los lados */
    }
  }

  &:nth-child(2) {
    width: 80%; /* Imagen del medio más grande */
  }
}

#imgCarousel {
  width: 95%;
}


.SeeMore {
  background-color: #EFE747;
  color: #0049FF;
  width: 180px;
  max-width: 200px;
  font-size: 25px;
  font-family: 'nunito-black', sans-serif; 
  margin-left: auto;
  display: block;
  margin-top: 20px;
  border: none;
  border-radius: 15px;
  margin-bottom: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right:15px;
  /* text-decoration: none; */
}




.SeeMore:hover {
  background-color: #0049FF;
  color: #EFE747;
  border: 2px solid  #EFE747;
}

.GAK-Info {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 465px) {
.GAK-Info {
  margin-left: 10px;
  margin-right: 10px;
}
}



.estiloBanner {
  background: #0049FF;
  /* background-size: cover; */
  /* height: 110vh; */
  /* justify-content: center; */
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  text-align: center;
}


/* .estiloBanner {
  background: url("../public/img/blue-background.jpg");
  background-size: cover;
  height: 110vh;
  justify-content: center;
  padding-top: 90px;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 709px) {
  .estiloBanner  {
    height: 90vh;
  }
}


@media only screen and (max-width: 390px) {
  .estiloBanner  {
    height: 110vh;
  }
}

@media only screen and (max-width: 378px) {
  .estiloBanner  {
    height: 130vh;
  }
} */



/* HOME GET A KRANE CAROUSEL */

.carousel .slide {
  display: flex;
  justify-content: center;
}

.carousel .slide img {
  max-width: 100%; /* Ajusta el ancho máximo según sea necesario */
  height: auto;
  transition: transform 0.5s ease; /* Agrega una transición suave para la animación */
}

.carousel .carousel-center {
  transform: scale(1.2); /* Ajusta el factor de escala para hacerla más grande */
}



.carousel-home {
  padding-left: 10px;
}

.splide__track {
  display: flex;
}

.splide__slide {
  flex: 0 0 33.33%; /* Ajusta el ancho según la cantidad de imágenes visibles */
}

.carousel-home {
  width: 100%;
  transition: transform 0.5s ease;
}

.center-slide {
  transform: scale(1.2); /* Ajusta el factor de escala según tus preferencias */
}

/* CSS file */
.carousel-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-container img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Adjust the value to control the roundness of the corners */
}

.center .carousel-container img {
  border-radius: 15px; /* You can use a different value for the center image if desired */
}









/* HIRE A KRANE HOME */

.hireAkrane {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #0049FF;
  margin-top: 60px;
}


.HAK-subtitle {
  font-family: 'nunito-bold', sans-serif;
  color: #5E5E5E;
font-size: 35px;
}


@media only screen and (max-width: 709px) {
  .HAK-subtitle  {
    font-size: 25px;
  }
}

.HAK-subtitle2 {
  font-family: 'nunito-black', sans-serif;
  color: #2E2E75;
  font-size: 40px;
  margin-top: 40px;
}

@media only screen and (max-width: 709px) {
  .HAK-subtitle2  {
    font-size: 35px;
  }
}

.HAK-subtitle3 {
  font-family: 'nunito-black', sans-serif;
  color: #2E2E75;
  font-size: 65px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 709px) {
  .HAK-subtitle3  {
    font-size: 45px;
  }
}


.SeeMoreHire {
  background-color: #0049FF;
  color: white;
  width: 180px;
  font-size: 25px;
  font-family: 'nunito-black', sans-serif; 
  /* margin-left: auto; */
  /* display: block; */
  /* margin-top: 20px; */
  border: none;
  border-radius: 15px;
  margin-bottom: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.SeeMoreHire:hover {
  background-color: #EFE747;
  color: #0049FF;
}



/*  HOME GALLERY  */

.photogallery-image {
  transition: filter 0.3s ease;
}

.photogallery-image:hover {
  filter: brightness(125%);
}



.HomeGallery{
  background-image: url('../public/img/kiwi-background.jpg');
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center;
  background-attachment: fixed;
  padding-top:50px;
}

@media only screen and (max-width: 375px) {
  .HomeGallery {
    margin-top:100px;
  }
}

.gallery-title {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #0049FF;
  /* margin-top: 60px; */
  margin-left: auto;
  margin-right:auto;
  background-color: #EFE747;
  text-align: center;
  margin-bottom: 60px;
  padding-top: 10px;
  padding-bottom: 15px;
  /* max-width: 100%; */
}

.HomeGalleryPhoto:hover {
  border: 1px solid white;
  filter: brightness(1.5);
  transform-origin: center center;
}


.SeeMoreGallery {
  background-color: #EFE747;
  color: #0049FF;
  max-width: 200px;
  font-size: 25px;
  font-family: 'nunito-black', sans-serif; 
  margin-left: 5px;
  display: block;
  margin-top: 20px;
  border: none;
  border-radius: 15px;
  margin-bottom: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.SeeMoreGallery:hover {
  background-color: #0049FF;
  color: white;
  /* border: 2px solid  #EFE747; */
}

#gallery {
  margin-left: 15px;
  margin-right: 15px;
}


/* FOOTER */


/* styles.css */

.footer {
  background-color: #2727C0;
  color: white;
}

.links-white {
  color: white;
  font-family: 'nunito', sans-serif;
  text-decoration: none;
  font-size: 19px; /* Cambiado a rem para mayor escalabilidad */
  /* line-height: 2; */
  max-width: 18rem; /* Cambiado a rem para mayor escalabilidad */
}

@media only screen and (max-width: 750px) {
  .footer {
    text-align: center;
  }
}



.links-white:hover {
  color: #F09606;
}

.footer-title {
  font-family: 'nunito-ExtraBold', sans-serif;
  font-size: 1.5rem; /* Cambiado a rem para mayor escalabilidad */
  margin-bottom: 10px;
}

.list-unstyled {
  list-style: none;
  padding: 0;
}

.links-container {
  display: flex;
  flex-direction: column;
}

.footer-logos,
.footer-logos2 {
  max-width: 55%;
  padding: 5px;
}

.footer-logos:hover,
.footer-logos2:hover {
  transform: scale(1.1); /* Ajusta según tus preferencias */
  transition: transform 0.3s ease; /* Transición suave */
}

.footer-columns {
  display: flex;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  height: auto;
}

.footer-column {
  flex: 1;
  padding: 1rem; /* Cambiado a rem para mayor escalabilidad */
}

@media only screen and (max-width: 750px) {
  .footer-columns {
    flex-direction: column;
  }

  .footer-column {
    margin-bottom: 20px;
  }

  .footer-logos,
  .footer-logos2 {
    max-width: 30%;
  }
}


.footer-columns {
  display: flex;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  height: auto;
}

.footer-column2 {
  flex: 1;
  padding: 1rem; /* Cambiado a rem para mayor escalabilidad */
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .footer-columns {
    flex-direction: column;
  }

  .footer-column2 {
    margin-bottom: 20px;
  }

  .footer-logos,
  .footer-logos2 {
    max-width: 30%;
  }
}

@media only screen and (max-width: 650px) {
  .footer-logos,
  .footer-logos2 {
    max-width: 40%;
  }
}

@media only screen and (max-width: 450px) {
  .footer-logos,
  .footer-logos2 {
    max-width: 50%;
  }
}

@media only screen and (max-width: 350px) {
  .footer-logos,
  .footer-logos2 {
    max-width: 60%;
  }
}



/* 
.footer {
  background-color: #2727C0;
  color: white;
}


.links-white {
  color: white;
  font-family: 'nunito-ExtraBold', sans-serif; 
  text-decoration: none;
  font-size: 15px;
  line-height: 2; 
max-width: 18px;

}

.links-white:hover {
  color: #F09606; 
}

.footer-title {
  font-family: 'nunito-ExtraBold', sans-serif; 
  font-size: 30px;
}

ul li.links-white {
  line-height: 4.5; 
}


.footer-logos {
  max-width: 55%;
  padding: 5px;
}

.footer-logos2 {
  max-width: 45%;
  padding: 5px;
}



@media only screen and (max-width: 750px) {
  .footer-logos, .footer-logos2  {
    max-width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .footer-logos, .footer-logos2  {
    max-width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .footer-logos, .footer-logos2 {
    max-width: 90%;
  }
}

@media only screen and (max-width: 350px) {
  .footer-logos, .footer-logos2  {
    max-width: 100%;
  }
} */





/* PRIZES */





.btn-modal {
  background-color: #0049FF;
  color: white;
  width: 100px;
  font-size: 20px;
  font-family: 'nunito-black', sans-serif; 
  margin-top: 20px;
  border: none;
  border-radius: 15px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  float: right;
}

.prizes-txt h1 {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #0049FF;
  margin-top: 60px;
  margin-left:15px;
  margin-right:15px;
}

.prizes-txt h2 {
  font-size: 30px;
  font-family: 'nunito-ExtraBold', sans-serif; 
  color: #5E5E5E;
  margin-bottom: 100px;
  margin-top:20px;
  margin-left:15px;
  margin-right:15px;
}

#margin {
  margin-left:15px;
  margin-right:15px;
}

.Photogallery {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}




/* GET A KRANE */

.list-type {
  list-style-type: none;
}

.getAkrane-Title {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #0049FF;
  margin-left: 15px;
  margin-right: 15px;
}

@media (max-width: 451px) {
  .getAkrane-Title  {
    font-size: 65px;
  }
} 


.getAkrane-Subtitle {
  font-family: 'nunito-ExtraBold', sans-serif; 
  /* font-size: 30px; */
  color: #5E5E5E;
  height: auto;
  margin-left: 15px;
  margin-right: 15px;
  /* width: 30px; */
}

.getAkrane-p {
  font-family: 'nunito-bold', sans-serif; 
  font-size: 20px;
  color: #5E5E5E;
  height: auto;
max-width: 800px;
margin-left: 15px;
margin-right: 15px;
}

@media only screen and (max-width: 765px) {
  .getAkrane-p {
    max-width: 600px
  }
}

.getAkrane-p a{
  color: #CE316A;
  text-decoration: none;
}


.img-krane {
  max-width: 55%;
  margin-right: auto;
  height: auto;
  margin-left: 30px;
}

.getAkrane1 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 20px;
  height: auto;
}

/* .getAkrane12 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
} */



@media only screen and (max-width: 767px) {
  .getAkrane12{
    margin-left:15px;
    margin-right:15px;
  }
}

@media only screen and (max-width: 767px) {
  .img-krane{
    max-width: 300px;
  }
}


.reason {
  background:#0049FF;
  /* background-size: cover; */
  /* height: 120vh; */
  /* justify-content: center; */
  padding-top: 0px;
  padding-bottom: 20px;
  color: white;
  text-align: center;
}


/* .reason {
  background: url("../public/img/blue-background.jpg");
  background-size: cover;
  height: 120vh;
  justify-content: center;
  padding-top: 90px;
  color: white;
  text-align: center;
}


@media only screen and (max-width: 805px) {
  .reason{
    height: 107vh;
  }
}

@media only screen and (max-width: 805px) {
  .reason{
    height: 120vh;
  }
}

@media only screen and (max-width: 793px) {
  .reason{
    height: 130vh;
  }
}

@media only screen and (max-width: 700px) {
  .reason{
    height: 140vh;
  }
}

@media only screen and (max-width: 669px) {
  .reason{
    height: 150vh;
  }
}

@media only screen and (max-width: 586px) {
  .reason{
    height: 160vh;
  }
}

@media only screen and (max-width: 557px) {
  .reason{
    height: 170vh;
  }
}

@media only screen and (max-width: 530px) {
  .reason{
    height: 180vh;
  }
}

@media only screen and (max-width: 503px) {
  .reason{
    height: 190vh;
  }
}

@media only screen and (max-width: 493px) {
  .reason{
    height: 195vh;
  }
}

@media only screen and (max-width: 461px) {
  .reason{
    height: 205vh;
  }
}

@media only screen and (max-width: 430px) {
  .reason{
    height: 220vh;
  }
}

@media only screen and (max-width: 411px) {
  .reason{
    height: 230vh;
  }
}


@media only screen and (max-width: 402px) {
  .reason{
    height: 235vh;
  }
}

@media only screen and (max-width: 395px) {
  .reason{
    height: 240vh;
  }
}

@media only screen and (max-width: 377px) {
  .reason{
    height: 345vh;
  }
}

@media only screen and (max-width: 365px) {
  .reason{
    height: 345vh;
  }
} */




.reason-getAkrane {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-top: 70px;

}

.marginLR {
  margin-left: 15px;
  margin-right: 15px;
}


.getAkrane-reason {
  font-family: 'nunito-ExtraBold', sans-serif; 
  color: #EFE747;
  height: auto;
  font-size: 55px;
  margin-left: 10px;
  margin-right: 15px;
  margin-bottom: 30px;
  padding-top:40px;
}

/* @media only screen and (max-width: 700px) {
  .getAkrane-reason {
padding-top: 40px;
  }
} */

@media only screen and (max-width: 400px) {
  .getAkrane-reason {
padding-top: 20px;
  }
}

.getAkrane-reason2{
  font-family: 'nunito-bold', sans-serif; 
  color: #EFE747;
  height: auto;
  font-size: 30px;
  margin-bottom: 40px;
  margin-left: 10px;
  /* margin-left: 20px; */
}


.item-reason {
  font-family: 'nunito-bold', sans-serif; 
  color: white;
  height: auto;
  font-size: 25px;
  /* line-height: 1.5; */
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  /* padding-right: 15px; */
  /* margin-left: 20px; */


  &::before {

    content: "";
    background-image: url('../public/img/bullet.png'); /* Ruta de tu imagen PNG */
    background-size: cover; /* Ajusta el tamaño de la imagen para cubrir el área */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    width: 20px; /* Ancho de la imagen */
    height: 20px; /* Alto de la imagen */
    position: absolute;
    left: 0;
    top: 13%; /* Centra verticalmente */
    transform: translateY(0%); /* Centra verticalmente */
  }
  margin-bottom: 20px;
}

/* ul {
  list-style-type: none;
} */



.SeeMoreReason {
  background-color: #EFE747;
  color: #0049FF;
  max-width: 200px;
  font-size: 25px;
  font-family: 'nunito-black', sans-serif; 
  margin-left: auto;
  display: block;
  margin-top: 20px;
  border: none;
  border-radius: 15px;
  margin-bottom: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 30px;
  margin-right:15px
 
}




.margin-title {
  padding-top:60px;
}

div.gallery {
  border: 2px solid rgba(255, 255, 255, 0);
  transition: transform 0.1s, border 0.1s, opacity 0.1s;

}

div.gallery:hover {
  /* border: 1px solid white; */
  filter: brightness(1.2);
  transform-origin: center center;
}



div.gallery img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

div.desc {
  padding: 15px;
  text-align: center;
}



.responsive {
  float: left;
  width: 24.99999%;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}




.close {
  background-color: #643f97;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close:hover {
  background-color: #643f97;
}


.GalleryHire {
  max-width: 1200px;
  margin-left: auto;
  margin-right:auto; 
  margin-top:70px;
  margin-bottom: 120px;
}


@media only screen and (max-width: 768px) {
  .GalleryHire {
    margin-top:50px;
  }
}



.gallery-section {
  background-image: url('../public/img/kiwi-background.jpg');
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center;
  background-attachment: fixed;
  color: white;
  padding-bottom: 50px;
  /* margin-top: 100px; */
}

@media only screen and (max-width: 768px) {
  .gallery-section {
    margin-top:60px;
  }
}


.GalleryHire {
  padding:20px
}


/* .load-more-button {
  width: 180px;
  height: auto;
  background-color: #643f97;
  border-color: #643f97;
  color: white;
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 50px;
margin-left: auto;
border-radius: 10px;
} */

/* .load-more-button:hover {
  background-color: #c3b5ea;
  border-color:#c3b5ea;
} */


.SeeMoreGallery {
  background-color: #0049FF;
  color: white;
  width: 180px;
  font-size: 25px;
  font-family: 'nunito-black', sans-serif; 
  /* margin-left: auto; */
  /* display: block; */
  /* margin-top: 20px; */
  border: none;
  border-radius: 15px;
  margin-bottom: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin-top: 40px; */
}

.SeeMoreGallery:hover {
  background-color: #EFE747;
  color: #0049FF;
  /* border: 2px solid  #0049FF; */
}



.GAK-gallery-title {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #0049FF;
  text-align: center;
  background-color: #EFE747;
}




/* GET A KRANE FORM */

.GAKraneForm-title {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #0049FF;
  text-align: center;
  background-color: #EFE747;
  padding-left:10px;
  padding-right:10px
}


.GAKraneForm-subtitle {
font-family: 'nunito-bold', sans-serif; 
font-size: 25px;
color: #5E5E5E;
height: auto;
max-width: 1000px;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
margin-bottom: 30px;
padding-left:10px;
padding-right:10px
}


#GAKrane-Form {
  max-width: 1000px;
/* padding-bottom: 100px; */
  margin-left: auto;
  margin-right: auto;
}

/* @media (max-width: 1024px) {
  #GAKrane-Form {
    margin-left: 250px;
    margin-right: 250px;
  }
} */


.Contact-container {
  background-color: #efefef9f;
/* margin-left: 15px;
margin-right: 15px; */
}


.form-label {
  font-family: 'nunito-bold', sans-serif; 
font-size: 19px;
color: #2E2E75;
}

.GAKrane-Form {
  background-color: white;
  padding: 25px;
  margin-top: 50px;
  /* margin-bottom: ; */
}


#btnForm {
  background-color: #0049FF;
  color: white;
  width: 180px;
  font-size: 25px;
  font-family: 'nunito-black', sans-serif; 
  /* margin-left: auto; */
  /* display: block; */
  margin-top: 20px;
  border: none;
  border-radius: 15px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin-top: 40px; */
  margin-right: 10px;
}

#btnForm:hover {
  background-color: #EFE747;
  color: #0049FF;
  /* border: 2px solid  #0049FF; */
}


/* get a krane gallery */

/* hacerlo para pantalla grande */
/* .modal-content {
  width: 150% !important; 
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (max-width: 1050px) {
  .modal-content  {
    height: 100vh !important; 
  }
}  */


/* HIRE A KRANE */

.prueba {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #CE316A; /* Change the color to match the inline style */
}

.item-reason-content {
  font-family: 'nunito-bold', sans-serif; 
  font-size: 19px;
  color: white;
  padding-right: 15px;
}

.reason2 {
  background:#0049FF;
  /* background-size: cover; */
  /* height: 140vh; */
  /* justify-content: center; */
  /* padding-top: 90px; */
  padding-bottom: 20px;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  padding-top:5px;
}


/* .reason2 {
  background: url("../public/img/blue-background.jpg");
  background-size: cover;
  height: 140vh;
  justify-content: center;
  padding-top: 90px;
  color: white;
  text-align: center;
}



@media (max-width: 712px) {
  .reason2  {
    height: 150vh;
  }
} 

@media (max-width: 676px) {
  .reason2  {
    height: 165vh;
  }
} 

@media (max-width: 580px) {
  .reason2  {
    height: 180vh;
  }
} 

@media (max-width: 509px) {
  .reason2  {
    height: 200vh;
  }
} 

@media (max-width: 467px) {
  .reason2  {
    height: 220vh;
  }
} 

@media (max-width: 448px) {
  .reason2  {
    height: 225vh;
  }
} 



@media (max-width: 414px) {
  .reason2  {
    height: 230vh;
  }
} 

@media (max-width: 404px) {
  .reason2  {
    height: 250vh;
  }
} 
@media (max-width: 390px) {
  .reason2  {
    height: 260vh;
  }
} 


@media (max-width: 376px) {
  .reason2  {
    height: 370vh;
  }
} 

@media (max-width: 360px) {
  .reason2  {
    height: 330vh;
  }
}  */


.downLine {
  font-family: 'nunito-bold', sans-serif; 
  font-size: 25px;
  color: white;
  padding-right: 15px;
  margin-top:30px
}

.downLineY {
  font-family: 'nunito-bold', sans-serif; 
  font-size: 25px;
  color: #EFE747;
  padding-right: 15px;
  margin-top: 30px;
}



/* CONTACT US */

#inputGroupPrepend {
  font-size: 20px;
}

/* .form-control {
  font-size: 20px;
} */

.contact-title {
font-family: 'beachday', sans-serif; 
font-size: 80px;
color: #0049FF;
/* margin-top: 60px; */
text-align: center;
/* padding-top: 50px; */
margin-left:15px;
margin-right:15px;
}


@media (max-width: 451px) {
  .contact-title  {
    font-size: 65px;
  }
} 




.contact-txt {
height: auto;
max-width: 900px;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
margin-bottom: 30px;
}

.contact-txt p {
  font-family: 'nunito-bold', sans-serif; 
  font-size: 25px;
  color: #5E5E5E;
  margin-left: 10px;
  margin-right: 10px;
}


.formApply {
  max-width: 800px;
  margin-left: auto;
margin-right: auto;
}

#formApllyContact {
    margin-left: 10px;
  margin-right: 10px;
}

.contact-container {
  max-width: 1000px;
    background: white;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-right: auto;
    margin-left: auto;
}


.contactUs {
  padding-bottom: 40px;
}


.ContactUs-container {
  background-color: #efefef9f;
  padding-top: 40px;
    padding-bottom: 40px;
}



/* ABOUT US */

.about {
  max-width: 1200px;
  margin-left: auto;
  margin-right:auto;
  height: auto;
}

@media (max-width: 1070px) {
  .about  {
    margin-left:20px;
    margin-right:20px;
  }
} 


/* .ver{
  max-width: 1200px;
  margin-left: auto;
  margin-right:auto;
  height: auto;
} */

.aboutUs-txt {
  margin-left:50px;
  margin-right:50px;
}

.aboutUs-txt h1 {
  font-family: 'beachday', sans-serif; 
  font-size: 80px;
  color: #0049FF;
  margin-top: 60px;
}

@media (max-width: 451px) {
  .aboutUs-txt h1  {
    font-size: 65px;
    margin-left:5px;
    margin-right:5px;
  }
} 

.aboutUs-txt h2 {
  font-size: 25px;
  font-family: 'nunito-Bold', sans-serif; 
  color: #5E5E5E;
  margin-bottom: 100px;
  margin-top:20px
}

.decorationNone {
  text-decoration: none;
}

.iconos {
  /* width: 20%; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* .image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 300px;
  height: auto;
} */

/* #image {
  max-width: 80%;
  height: auto;
} */

.image {
  margin:40px;
  max-width: 80%;
  height: auto;
  margin-top: 0px;
}

/* .iconos div {
  flex: 1;
  max-width: 300px;
  text-align: center;
} */

.align {
  text-align: center;
  font-size: 20px;
  font-family: 'nunito-ExtraBold', sans-serif; 
  color: #5E5E5E;
  margin-bottom: 100px;
  /* margin-top:20px */
}



/* PRUEBA CAROUSEL */
